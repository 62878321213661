import type { ApiTypes } from '@hyundai/ng-common-lib';
import { DialogTag } from '@pixelgenau/ngx-px-dialog';

export interface M040C010EnvkvOverlayDialogData {
    model: string;
    description?: string;
    data: { envkv: ApiTypes.Envkv; score: string };
}

export const M040C010EnvkvOverlayDialogTag = new DialogTag<M040C010EnvkvOverlayDialogData, boolean>(
    'm040-c010-envkv-overlay-dialog',
    async () => {
        const { M040C010EnvkvOverlayComponent } = await import('./m040-c010-envkv-overlay.component');
        return M040C010EnvkvOverlayComponent;
    },
);
